import { call, put, takeLatest } from "redux-saga/effects";
import { cancelRetrieveCheckinRequest, cancelRetrieveCheckinSuccess, cancelRetrieveCheckinFailure } from "../../slice/cancelCheckin/cancelRetrieveJourneySlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchCancelRetrieveCheckin = async cancelCheckin => {
  const API_URL = config.DAPI_CANCEL_RETRIEV_CHECKIN;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: sessionStorage.getItem("checkintoken"),
      "Accept-Language": "".concat(i18n.language),
      "Content-Type": "application/json"
    },
    loader: true
  };
  let cancelRetrieveCheckinPayload = {
    journeyId: cancelCheckin === null || cancelCheckin === void 0 ? void 0 : cancelCheckin.journeyId
  };
  const response = await api.post(API_URL, cancelRetrieveCheckinPayload, data);
  return response;
};
function* getRetrieveCancelCheckin(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchCancelRetrieveCheckin, payload);
    yield put(cancelRetrieveCheckinSuccess(response.data));
  } catch (error) {
    yield put(cancelRetrieveCheckinFailure(error));
  }
}
export function* watchRetrieveCancelCheckin() {
  yield takeLatest(cancelRetrieveCheckinRequest.type, getRetrieveCancelCheckin);
}