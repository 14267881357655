import { call, put, takeLatest } from "redux-saga/effects";
import { selectPassengerRequest, selectPassengerSuccess, selectPassengerFailure, generateOtpRefKey, generateOtpRefKeyFailure, generateOtpRefKeySuccess, generateResendOtp, generateResendOtpSuccess, generateResendOtpFailure, getSubmitOtpReq, submitOtpFailure, submitOtpSuccess } from "../../slice/checkin/selectPassengerDetailsSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import i18n from "../../../../i18n";
const fetchSelectPassengerData = async selectPassengers => {
  const SELECTION_PASSENGER_API_URL = config.SELECT_PASSENGER_REGULATORY_CHECKIN;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Accept-Language": "".concat(i18n.language),
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post("".concat(SELECTION_PASSENGER_API_URL), selectPassengers, data);
  return response;
};
function* getSelectPassengerData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchSelectPassengerData, payload);
    yield put(selectPassengerSuccess(response.data));
  } catch (error) {
    yield put(selectPassengerFailure(error));
  }
}
export function* watchSelectPassengerData() {
  yield takeLatest(selectPassengerRequest.type, getSelectPassengerData);
}
const fetchOtpRefKey = async emailId => {
  const FETCH_OTP_REF_KEY = config.CHECKIN_SEND_OTP;
  const data = {
    mode: "no-cors",
    headers: {
      "Accept-Language": "".concat(i18n.language),
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post("".concat(FETCH_OTP_REF_KEY), emailId, data);
  return response;
};
function* getGenerateOtpRefKeyData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchOtpRefKey, payload);
    yield put(generateOtpRefKeySuccess(response.data));
  } catch (error) {
    yield put(generateOtpRefKeyFailure(error));
  }
}
export function* watchGenerateOtpRefKeyData() {
  yield takeLatest(generateOtpRefKey.type, getGenerateOtpRefKeyData);
}
const checkinResendOtp = async emailId => {
  const FETCH_OTP_REF_KEY = config.CHECKIN_RESEND_OTP;
  const data = {
    mode: "no-cors",
    headers: {
      "Accept-Language": "".concat(i18n.language),
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post("".concat(FETCH_OTP_REF_KEY), emailId, data);
  return response;
};
function* getCheckinResendOtp(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(checkinResendOtp, payload);
    yield put(generateResendOtpSuccess(response.data));
  } catch (error) {
    yield put(generateResendOtpFailure(error));
  }
}
export function* watchGenerateResendOtp() {
  yield takeLatest(generateResendOtp.type, getCheckinResendOtp);
}
const submitOtp = async emailId => {
  const FETCH_OTP_REF_KEY = config.CHECKIN_ADD_FFP;
  const data = {
    mode: "no-cors",
    headers: {
      "Accept-Language": "".concat(i18n.language),
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  const response = await api.post("".concat(FETCH_OTP_REF_KEY), emailId, data);
  return response;
};
function* getSubmitOtp(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(submitOtp, payload);
    yield put(submitOtpSuccess(response.data));
  } catch (error) {
    yield put(submitOtpFailure(error));
  }
}
export function* watchSubmitOtp() {
  yield takeLatest(getSubmitOtpReq.type, getSubmitOtp);
}