import { call, put, takeLatest } from "redux-saga/effects";
import { checkinBoardingPassDataRequest, getCheckinBoardingPassSuccess, getCheckinBoardingPassFailure, googleWalletBpSuccess, googleWalletBpFailure, googleWalletBpRequest, appleWalletBpRequest, appleWalletBpSuccess, appleWalletBpFailure } from "../../slice/checkin/checkinBoardingPassSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
const headerData = {
  mode: "no-cors",
  headers: {
    accesstoken: localStorage.getItem("accesstoken"),
    "Content-Type": "application/json"
  },
  loader: true
};
const fetchBoardingPassData = async payload => {
  const BOARDING_PASS_API_URL = config.CHECKIN_BOARDING_PASS;
  let payloadData = {
    journeyId: payload
  };
  const response = await api.post(BOARDING_PASS_API_URL, payloadData, headerData);
  return response;
};
function* getCheckinBoardingPassData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchBoardingPassData, payload);
    yield put(getCheckinBoardingPassSuccess(response === null || response === void 0 ? void 0 : response.data));
  } catch (error) {
    yield put(getCheckinBoardingPassFailure(error === null || error === void 0 ? void 0 : error.response));
  }
}
export function* watchCheckinBoardingPassData() {
  yield takeLatest(checkinBoardingPassDataRequest.type, getCheckinBoardingPassData);
}
const fetchBoardingPassGoogleWallet = async payload => {
  const BOARDING_PASS_GOOGLE_API_URL = config.CHECKIN_BOARDING_PASS_GOOGLE_WALLET;
  const response = await api.post(BOARDING_PASS_GOOGLE_API_URL, payload, headerData);
  return response;
};
function* getCheckinBoardingPassGoogleWallet(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchBoardingPassGoogleWallet, payload);
    yield put(googleWalletBpSuccess(response));
  } catch (error) {
    yield put(googleWalletBpFailure(error === null || error === void 0 ? void 0 : error.response));
  }
}
export function* watchCheckinBoardingPassGoogleWallet() {
  yield takeLatest(googleWalletBpRequest.type, getCheckinBoardingPassGoogleWallet);
}
const fetchBoardingPassAppleWallet = async payload => {
  const BOARDING_PASS_APPLE_API_URL = config.CHECKIN_BOARDING_PASS_APPLE_WALLET;
  const response = await api.post(BOARDING_PASS_APPLE_API_URL, payload, headerData);
  return response;
};
function* getCheckinBoardingPassAppleWallet(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(fetchBoardingPassAppleWallet, payload);
    yield put(appleWalletBpSuccess(response));
  } catch (error) {
    yield put(appleWalletBpFailure(error === null || error === void 0 ? void 0 : error.response));
  }
}
export function* watchCheckinBoardingPassAppleWallet() {
  yield takeLatest(appleWalletBpRequest.type, getCheckinBoardingPassAppleWallet);
}